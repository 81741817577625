<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :max-width="1200"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="elevation-3 rounded-lg d-flex flex-column"
      :max-width="1200"
    >
      <v-card-title>
        <span>
          {{ $t('messages.views.admin.components.configuration.maintenance.commandLineInterface.title', {stationWatcherName: stationWatcherName}) }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text>
        <v-alert
          type="info"
        >
          {{ $t('messages.views.admin.components.configuration.maintenance.commandLineInterface.description') }}
        </v-alert>

        <v-sheet
          ref="cli"
          color="black"
          rounded
          elevation="1"
          class="mb-2 pa-2 overflow-y-auto white--text"
          :style="{
            height: '300px',
          }"
        >
          <div
            v-for="(exchange, i) of exchanges"
            :key="`exchange-${i}`"
          >
            <div>
              <v-icon color="white">
                mdi-chevron-right
              </v-icon>
              <span
                v-html="formatMessage(exchange.request)"
              />
            </div>
            <div>
              <v-icon color="white">
                mdi-chevron-left
              </v-icon>
              <o-inline-loader v-if="exchange.loading" />
              <span
                v-else-if="exchange.response"
                :class="{'danger--text font-weight-bold': exchange.response.errors}"
                v-html="formatResponse(exchange.response)"
              />
              <span
                v-else
                class="danger--text font-weight-bold"
                v-html="$t('messages.views.admin.components.configuration.maintenance.commandLineInterface.responseError')"
              />
            </div>
          </div>
        </v-sheet>
        <o-form @submit="onSubmit">
          <o-text-field
            v-model="request"
            outlined
            prepend-inner-icon="mdi-chevron-right"
            hide-details
            autofocus
            @keydown.ctrl.l.capture.prevent.stop="clear"
            @keydown.up.capture.prevent.stop="previous"
            @keydown.down.capture.prevent.stop="next"
            @keydown="historyIndex = 0"
          >
            <template v-slot:append>
              <o-submit
                icon="mdi-send"
                class="mt-n2"
                :disabled="currentExchange !== null"
              />
            </template>
          </o-text-field>
        </o-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      stationWatcherName: {
        type: String,
        required: true,
      },
    },

    data: function () {
      return {
        model: true,
        request: null,
        exchanges: [],
        currentExchange: null,
        historyIndex: 0,
      };
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    methods: {
      onSubmit () {
        this.currentExchange = {
          request: {
            sentAt: new Date(),
            text: this.request,
          },
          loading: true,
          response: null,
        };
        this.exchanges.push(this.currentExchange);
        this.$nextTick(this.scrollToBottom);

        this.$apollo.mutate({
          mutation: require('@gql/mutations/station/sendMessageToStationWatcher.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              stationWatcherName: this.stationWatcherName,
              message: this.request,
            },
          },
        }).then((response) => {
          const result = response.data.sendMessageToStationWatcher;

          if (result.text !== null) {
            this.currentExchange.response = {
              sentAt: new Date(),
              text: result.text,
            };
          } else if (result.errors) {
            this.currentExchange.response = {
              sentAt: new Date(),
              errors: result.errors,
            };
          } else {
            this.currentExchange.response = false;
          }
        }).catch((e) => {
          this.currentExchange.response = false;
          this.$flash(null, 'error');
        }).finally(() => {
          this.currentExchange.loading = false;
          this.currentExchange = null;
        });

        this.request = null;
      },

      scrollToBottom () {
        const cli = this.$refs.cli.$el;
        cli.scrollTop = cli.scrollHeight;
      },

      formatResponse (message) {
        if (message.text !== undefined) {
          console.log(message.text);
          return this.formatMessage(message);
        } else {
          return this.formatErrors(message);
        }
      },

      formatMessage (message) {
        return '[' +
          moment.utc(message.sentAt).format('L LTS') +
          '] ' +
          message.text
        ;
      },

      formatErrors (message) {
        return '[' +
          moment.utc(message.sentAt).format('L LTS') +
          '] ' + message.errors.join(', ')
        ;
      },

      clear () {
        if (this.currentExchange !== null) {
          this.exchanges = [this.currentExchange];
        } else {
          this.exchanges = [];
        }
        this.historyIndex = 0;
      },

      previous () {
        if (this.historyIndex < this.exchanges.length) {
          this.historyIndex++;

          this.request = this.exchanges[this.exchanges.length - this.historyIndex].request.text;
        }
      },

      next () {
        if (this.exchanges.length && this.historyIndex > 1) {
          this.historyIndex--;

          this.request = this.exchanges[this.exchanges.length - this.historyIndex].request.text;
        }
      },
    },
  };
</script>
